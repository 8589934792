import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/layout-tailwind-mdx.tsx";
import { ProductFeatureRow } from '@/components/tailwind/product-feature-row';
import { ProductSection } from '@/components/tailwind/product-section';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <p>{`id: my-doc-id
title: My document title
description: My document description
slug: /my-custom-url`}</p>
    <hr></hr>
    <section>
      <ProductFeatureRow index={0} title={`Genuine Leather`} subTitle={`Craftsmanship & Materials`} description={`Reducing your bulk is in our DNA. Our wallets are the slimmest to date and have more space than ever. You can seamlessly carry up to 12 cards & 20 Bills at a fraction of the size of a traditional wallet.`} imageName={`air-wallet-promo-1.jpg`} mdxType="ProductFeatureRow" />
      <ProductFeatureRow index={1} title={`Minimalist, Light and Elegant Design`} subTitle={`Less bulk. More storage.`} description={`The wallet is Slim, sleek and has a modular design that will be the perfect touch for your everyday carry essentials since it can perfectly fit discreetly in your front pocket. It's time to ditch that bulky back breaking wallet!`} imageName={`air-wallet-promo-2.jpg`} mdxType="ProductFeatureRow" />
      <ProductFeatureRow index={2} title={`Anti RFID Technology`} subTitle={`Peace of mind.`} description={`We incorporated the most advanced technology for wallets that you have ever seen. The aluminum compartment that we have built inside the wallet secures the data that your cards have, making it impossible for thieves to steal your credit card personal information with a wireless device.`} imageName={`air-wallet-promo-3.jpg`} mdxType="ProductFeatureRow" />
      <ProductFeatureRow index={3} title={`Redefining wallet`} subTitle={`Less bulk. More storage.`} description={`Hand-crafted layers, meticulously sewn to each other. Perfect stitching to weave the wallet together. As this leather ages, that shine is replaced with a matte finish, and the leather becomes softer with use.`} imageName={`air-wallet-promo-4.jpg`} mdxType="ProductFeatureRow" />
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      